import React, { Suspense } from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import './i18n'; // Importa la configuración de i18next

// Configuración de Suspense para lazy loading y manejo de la app en caso de errores.
const container = document.getElementById('root');
const root = createRoot(container);

Modal.setAppElement("#root");

const Loader = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <div className="spinner" />
    <style>{`
      .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: #000;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }

      @keyframes spin {
        to { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

// Puedes envolver toda tu app en Suspense para lazy loading y mejorar la percepción de rendimiento.
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </React.StrictMode>
);
