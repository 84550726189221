import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { motion } from "framer-motion";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import Services from "components/features/DashedBorderSixFeatures";
import Footer from "components/footers/MiniCenteredFooter";

const darkBlue = "var(--color1)";
const primaryColor = "var(--color1)"; // Ajuste para importar colores desde GlobalStyles

const Subheading = styled(SubheadingBase)`
  margin-bottom: 0.5rem; /* Reduce el margen inferior del subheading */
  text-align: center;
  font-family: 'KoHo', sans-serif;
  color: ${primaryColor};
`;

const Heading = styled(SectionHeading)`
  width: 100%;
  font-family: 'KoHo', sans-serif;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 0.25rem; /* Reduce el margen inferior del heading */
  
  span {
    color: ${darkBlue};
  }
`;

const Description = styled(SectionDescription)`
  width: 100%;
  text-align: center;
  font-family: 'KoHo', sans-serif; /* Aplica la fuente KoHo */
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem; /* Reduce el margen inferior */
`;

const FAQSContainer = styled.dl`
  margin-top: 1.5rem; /* Reduce el margen superior del contenedor de FAQs */
  max-width: 64rem;
  position: relative;
`;

const FAQ = styled.div`
  cursor: pointer;
  user-select: none;
  margin-top: 1.25rem;
  padding: 1.25rem 2rem;
  border-radius: 0.5rem;
  background-color: var(--color2); 
  transition: background-color 0.3s ease;
`;

const Question = styled.dt`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const QuestionText = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  font-family: 'KoHo', sans-serif; 
  text-align: left; /* Cambia de justify a left */
  word-spacing: normal; /* Asegúrate de no aplicar espaciado entre palabras */
`;

const QuestionToggleIcon = motion(styled.span`
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`);

const Answer = motion(styled.dd`
  pointer-events: none;
  font-size: 1rem;
  line-height: 1.75;
  margin-top: 1rem;
  font-family: 'KoHo', sans-serif; 
  text-align: left; /* Cambia de justify a left */
  word-spacing: normal; /* Asegúrate de no aplicar espaciado entre palabras */
`);

export default () => {
  const { t } = useTranslation();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveQuestionIndex(prevIndex => prevIndex === index ? null : index);
  };

  const faqs = [
    {
      question: t('faqs.question1'),
      answer: t('faqs.answer1')
    },
    {
      question: t('faqs.question2'),
      answer: t('faqs.answer2')
    },
    {
      question: t('faqs.question3'),
      answer: t('faqs.answer3')
    },
    {
      question: t('faqs.question4'),
      answer: t('faqs.answer4')
    },
    {
      question: t('faqs.question5'),
      answer: t('faqs.answer5')
    },
    {
      question: t('faqs.question6'),
      answer: t('faqs.answer6')
    }
  ];

  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <Column>
            <Services />
            <HeaderContent>
              <Heading>{t('faqs.heading')}</Heading>
              <Subheading>{t('faqs.subheading')}</Subheading>
            </HeaderContent>
            <FAQSContainer id="faqs-section">
              {faqs.map((faq, index) => (
                <FAQ
                  key={index}
                  onClick={() => toggleQuestion(index)}
                  aria-expanded={activeQuestionIndex === index}
                  aria-controls={`faq-answer-${index}`}
                >
                  <Question>
                    <QuestionText>{faq.question}</QuestionText>
                    <QuestionToggleIcon
                      variants={{
                        collapsed: { rotate: 0 },
                        open: { rotate: -180 }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      <ChevronDownIcon />
                    </QuestionToggleIcon>
                  </Question>
                  <Answer
                    id={`faq-answer-${index}`}
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "1rem" },
                      collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    {faq.answer}
                  </Answer>
                </FAQ>
              ))}
            </FAQSContainer>
          </Column>
        </ContentWithPaddingXl>
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
