import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslation } from 'react-i18next';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import styled from "styled-components";
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import imageWtc from "images/wtc1.webp";

const Footer = lazy(() => import("components/footers/MiniCenteredFooter"));
const Map = lazy(() => import("components/map.js"));

const Address = styled.span`
  font-family: 'KoHo', sans-serif;
  font-size: 1.25rem;
  color: var(--color5);
  line-height: 1.75;
  text-align: left;
`;

const AddressLine = styled.span`
  display: block;
`;

const Email = styled.span`
  margin-top: 1.5rem;
  display: block;
  font-size: 1.125rem;
  color: var(--color4);
  font-family: 'KoHo', sans-serif;
  text-align: left;
`;

const Phone = styled.span`
  margin-top: 0;
  display: block;
  font-size: 1.125rem;
  color: var(--color4);
  font-family: 'KoHo', sans-serif;
  text-align: left;
`;

const ContactDetailsContainer = styled.div`
  padding-top: 6rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 1024px) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ContactTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 500px;
  text-align: left;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  @media (min-width: 1024px) {
    justify-content: flex-start;
    margin-top: 0;
  }
`;

const ImageBox = styled.div`
  padding: 1rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 350px;

  @media (max-width: 768px) {
    max-width: 300px;
  }
`;

const Image = styled.img.attrs({
  loading: 'lazy', 
  width: '350', 
  height: '300' 
})`
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Heading = styled.h2`
  font-size: 2rem;
  color: var(--color5);
  font-family: 'KoHo', sans-serif;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
`;

const Subheading = styled.h3`
  font-size: 1.5rem;
  color: var(--color5);
  font-family: 'KoHo', sans-serif;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: var(--color1);
  font-family: 'KoHo', sans-serif;
  line-height: 1.75;
  margin-top: 1rem;
  text-align: left;

  @media (min-width: 1024px) {
    font-size: 1.125rem;
  }

  @media (max-width: 480px) {
    font-size: 0.875rem;
  }
`;

const ContactPage = () => {
  const { t } = useTranslation();
  const [isMapVisible, setIsMapVisible] = useState(false);
  const mapRef = React.useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsMapVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (mapRef.current) {
      observer.observe(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        observer.unobserve(mapRef.current);
      }
    };
  }, []);

  return (
    <AnimationRevealPage>
      <ContactDetailsContainer>
        <ImageContainer>
          <ImageBox>
            <Image
              src={imageWtc}
              alt={t('ubi.image_alt')} 
              width={350}
              height={300}
            />
          </ImageBox>
        </ImageContainer>
        <ContactTextContainer>
          <Subheading>{t('ubi.subheading')}</Subheading>
          <Heading>{t('ubi.heading')}</Heading>
          <Description>{t('ubi.description')}</Description>
          <Address>
            <AddressLine>{t('ubi.address_line1')}</AddressLine>
            <AddressLine>{t('ubi.address_line2')}</AddressLine>
          </Address>
          <Email>{t('ubi.email')}</Email>
          <Phone>{t('ubi.phone')}</Phone>
        </ContactTextContainer>
      </ContactDetailsContainer>

      <div ref={mapRef}>
        {isMapVisible && (
          <Suspense fallback={<div>{t('ubi.loading_map')}</div>}>
            <Map />
          </Suspense>
        )}
      </div>

      <Suspense fallback={<div>{t('ubi.loading_footer')}</div>}>
        <Footer />
      </Suspense>
    </AnimationRevealPage>
  );
};

export default ContactPage;
