import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useTranslation } from 'react-i18next'; 
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: ${({ isScrolled }) => (isScrolled ? "white" : "transparent")};
  box-shadow: ${({ isScrolled }) => (isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none")};
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  min-height: 64px;
`;

const NavContainer = styled.nav`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 2rem;
`;

const LogoLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  position: relative; /* Permite que los logos se posicionen uno sobre otro */
  width: 96px; /* Aseguramos un ancho consistente */
  height: 96px; /* Aseguramos un alto consistente */

  @media (max-width: 1024px) {
    width: 80px;
    height: 80px;
  }

  img {
    position: absolute; /* Coloca ambos logos en el mismo lugar */
    top: 0;
    left: 0;
    width: 100%; /* Escala el logo al tamaño del contenedor */
    height: auto; /* Mantiene la proporción del logo */
    transition: opacity 0.3s ease, visibility 0.3s ease; /* Suaviza la transición */
  }

  .dark-logo {
    opacity: ${({ isScrolled }) => (isScrolled ? "1" : "0")};
    visibility: ${({ isScrolled }) => (isScrolled ? "visible" : "hidden")};
  }

  .light-logo {
    opacity: ${({ isScrolled }) => (isScrolled ? "0" : "1")};
    visibility: ${({ isScrolled }) => (isScrolled ? "hidden" : "visible")};
  }
`;


export const NavLink = styled.a`
  font-size: 1.125rem;
  margin: 0.5rem 0;
  font-family: 'KoHo', sans-serif;
  color: ${({ isScrolled }) => (isScrolled ? "#505151" : "white")};
  @media (min-width: 1024px) {
    font-size: 0.875rem;
    margin: 0 1.5rem;
  }
  font-weight: 600;
  transition: color 0.3s, border-color 0.3s;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid transparent;

  &:hover,
  &:focus {
    border-color: #8080e5;
    color: #8080e5;
  }
`;

export const PrimaryLink = styled(NavLink)`
  margin: 0;
  padding: 0.5rem 1.5rem;
  border-radius: ${({ roundedHeaderButton }) => (roundedHeaderButton ? '50px' : '30px')};
  background-color: ${({ isScrolled }) => (isScrolled ? "#8080e5" : "transparent")};
  color: ${({ isScrolled }) => (isScrolled ? "#ffffff" : "white")};
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover,
  &:focus {
    background-color: #7e7eba;
    color: #f7fafc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
  }
`;

const MobileNavLinksContainer = styled.nav`
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const NavToggle = styled.button`
  display: block;
  z-index: 1100;
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;

  &:focus {
    outline: none;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
    @media (max-width: 1024px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MobileNavLinks = styled(motion.div)`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 229, 0.9); 
    z-index: 1000;
    padding: 2rem;
    box-shadow: none;
  }
`;

const DesktopNavLinks = styled.nav`
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }
`;

const HeaderComponent = ({ roundedHeaderButton = false, links, className }) => {
  const { t } = useTranslation();
  const [showNavLinks, setShowNavLinks] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Cambia si haces scroll más de 50px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleNavbar = () => {
    setShowNavLinks(!showNavLinks);
  };

  const defaultLogoLink = (
    <LogoLink to="/" isScrolled={isScrolled}>
      {/* Logo oscuro */}
      <img
        src="/logochocho.webp"
        alt={t('header.logo_alt')}
        className="dark-logo"
      />
      {/* Logo claro */}
      <img
        src="/logochocowhite.webp"
        alt={t('header.logo_alt')}
        className="light-logo"
      />
    </LogoLink>
  );

  const defaultLinks = (
    <>
      <NavLink isScrolled={isScrolled} href="/#">{t('header.nav_home')}</NavLink>
      <NavLink isScrolled={isScrolled} href="/servicios">{t('header.nav_services')}</NavLink>
      <NavLink isScrolled={isScrolled} href="/quienes-somos">{t('header.nav_about_us')}</NavLink>
      <NavLink isScrolled={isScrolled} href="/ubicacion">{t('header.nav_location')}</NavLink>
      <PrimaryLink
        isScrolled={isScrolled}
        href="https://wa.me/+5491160575569?text=Hola, me gustaría obtener más información sobre sus servicios."
        roundedHeaderButton={roundedHeaderButton}
      >
        {t('header.contact')}
      </PrimaryLink>
    </>
  );

  return (
    <Header className={className || "header-light"} isScrolled={isScrolled}>
      <NavContainer>
        {defaultLogoLink}
        <DesktopNavLinks>{links || defaultLinks}</DesktopNavLinks>
        <MobileNavLinksContainer>
          <NavToggle aria-label={t('header.toggle_nav')} onClick={toggleNavbar}>
            {showNavLinks ? <CloseIcon /> : <MenuIcon />}
          </NavToggle>
        </MobileNavLinksContainer>
      </NavContainer>
      <MobileNavLinks initial={{ x: "100%" }} animate={{ x: showNavLinks ? 0 : "100%" }}>
        {links || defaultLinks}
      </MobileNavLinks>
    </Header>
  );
};

export default HeaderComponent;
