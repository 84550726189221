import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Detecta si está en modo producción para desactivar el debug
const isProduction = process.env.NODE_ENV === 'production';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es', // Idioma predeterminado
    debug: !isProduction, // Solo habilitar en desarrollo

    interpolation: {
      escapeValue: false, // React ya escapa los valores por defecto
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Ruta para cargar las traducciones
    },

    detection: {
      // Detectores de idioma (ordena según tus necesidades)
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'], // Almacena en el localStorage
    },

    react: {
      useSuspense: false, // Desactivar Suspense para evitar carga innecesaria
    },

    // Optimización adicional: cargar recursos cuando sea necesario
    preload: ['es', 'en'], // Idiomas que puedes precargar
  });

// Establecer explícitamente el idioma inicial solo si no está definido
if (!i18n.language) {
  i18n.changeLanguage('es');
}

export default i18n;
