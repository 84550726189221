import React, { useEffect } from "react";
import styled from "styled-components";

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  border: 2px solid var(--color1);
  border-radius: 0.375rem;
  padding: 1rem;
  margin: 2rem auto;
  width: 100%;
  max-width: 90%;
  background-image: url("/wtcmap.webp");
  background-size: cover;
  background-position: center;
`;

const MapText = styled.div`
  font-size: 1.25rem; /* Tamaño de fuente base */
  font-weight: 600; /* Peso de la fuente seminegrita */
  margin-bottom: 1rem;
  text-align: center;
  color: var(--color1);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: 1px solid var(--color1);
  max-width: 80%;
  margin-top: 1rem;

  display: flex; /* Para permitir el alineamiento horizontal entre el texto y la imagen */
  align-items: center; /* Alinea verticalmente el contenido */
  justify-content: center; /* Centra horizontalmente */

  img {
    width: 40px; /* Ajusta el tamaño del logo aquí */
    height: auto;
    margin-right: 0.5rem; /* Espacio entre la imagen y el texto */
  }

  /* Ajustes para pantallas más pequeñas */
  @media (max-width: 768px) {
    font-size: 1rem; /* Tamaño de fuente más pequeño para pantallas móviles */
    padding: 0.5rem; /* Reduce el padding */
    max-width: 95%; /* Aumenta el ancho para que el texto ocupe más espacio horizontal */
    
    img {
      width: 30px; /* Reduce el tamaño del logo en pantallas móviles */
      margin-right: 0.3rem;
    }
  }

  /* Ajustes para pantallas aún más pequeñas (por debajo de 480px) */
  @media (max-width: 480px) {
    font-size: 0.875rem; /* Fuente aún más pequeña en pantallas muy pequeñas */
    padding: 0.3rem; /* Reduce el padding aún más */
    max-width: 100%; /* Ocupa el 100% del ancho disponible */
    
    img {
      width: 25px; /* Reduce el logo un poco más */
    }
  }

  /* Ajustes para pantallas grandes */
  @media (min-width: 1024px) {
    font-size: 1.5rem; /* Tamaño de fuente más grande para pantallas grandes */
    max-width: 70%;
    padding: 1rem 2rem; /* Aumenta el padding para pantallas grandes */
    
    img {
      width: 50px; /* Aumenta el tamaño del logo */
    }
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;
  border-radius: 0.375rem;

  @media (min-width: 768px) {
    height: 400px;
  }

  @media (min-width: 1024px) {
    height: 450px;
  }
`;

const Map = () => {
  useEffect(() => {
    const lazyLoad = () => {
      const iframe = document.getElementById("map-iframe");
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              iframe.src = iframe.dataset.src;
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );

      observer.observe(iframe);
    };

    lazyLoad();
  }, []);

  return (
    <MapWrapper>
      <MapText>
      <img src="/chochominimal.png" alt="Logo" /> {/* Ruta relativa desde public */}
        Visita nuestra oficina en la torre 3 piso 11 del WTC Asunción
      </MapText>
      <Iframe
        id="map-iframe"
        data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.4649170133083!2d-57.56970528489396!3d-25.2842847442241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da8a7ad75d563%3A0x6725a6261677900f!2sWorld%20Trade%20Center%20Asunci%C3%B3n!5e0!3m2!1ses!2spy!4v1620345633921!5m2!1ses!2spy"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </MapWrapper>
  );
};

export default Map;
