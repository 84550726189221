import React from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import { FaGavel, FaUniversity, FaCalculator, FaTrademark, FaBoxes, FaPassport } from "react-icons/fa";
import { useTranslation } from 'react-i18next'; // Importamos el hook de traducción

const Container = tw.div`relative `;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-16`}
  // Ajuste del padding-top y padding-bottom para reducir el espacio
  padding-top: 2rem; /* Ajusta este valor para reducir el espacio superior */
`;


const Heading = styled.h2`
  ${tw`w-full text-center text-4xl md:text-5xl lg:text-6xl font-black`} 
  font-family: 'KoHo', sans-serif; 
  color: var(--color5); 
  margin-bottom: 1rem; 
`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const futuristicAnimation = keyframes`
  0% { transform: scale(0.95); opacity: 0; }
  50% { transform: scale(1.05); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
`;

const Card = styled.a`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 rounded-lg mt-12 relative`}
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease-in-out;
  background-color: white;
  border-color: var(--color5);
  &:hover {
    background-color: var(--color6); 
    border-color: var(--color4); 
    box-shadow: 0 0 20px rgba(128, 128, 229, 0.5);
  }
  &:hover .hoverContent {
    display: flex;
    animation: ${futuristicAnimation} 0.5s forwards;
  }
  .imageContainer {
    ${tw`border-2 text-center rounded-full p-6 flex-shrink-0 relative`}
    border-color: var(--color5);
    svg {
      ${tw`w-8 h-8`}
      object-fit: cover;
      color: var(--color);
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none`}
    color: var(--color5);
  }

  .description {
    ${tw`mt-3 font-semibold text-sm leading-loose`}
    color: var(--color5);
  }

  .hoverContent {
    ${tw`absolute top-0 left-0 w-full h-full bg-white bg-opacity-75 flex items-center justify-center rounded-lg`}
    display: none;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--color1);
    pointer-events: none;
    border: 2px solid var(--color1);
    box-shadow: 0 0 20px rgba(128, 128, 229, 0.5);
    transform: scale(0.95);
  }

  @media (max-width: 768px) {
    ${tw`px-4 py-8`}
    .imageContainer {
      ${tw`p-4`}
      svg {
        ${tw`w-6 h-6`}
      }
    }
    .title {
      ${tw`text-lg`}
    }
    .description {
      ${tw`text-xs`}
    }
  }

  @media (max-width: 480px) {
    ${tw`px-2 py-6`}
    .imageContainer {
      ${tw`p-2`}
      svg {
        ${tw`w-4 h-4`}
      }
    }
    .title {
      ${tw`text-base`}
    }
    .description {
      ${tw`text-xs`}
    }
  }
`;

export default () => {
  const { t } = useTranslation(); // Usamos el hook de traducción

  const whatsappNumber = "5491160575569"; 
  const defaultMessage = t("default_message");

  const cards = [
    { 
      icon: <FaGavel aria-label={t('constitution_of_company_icon')} />, 
      title: t('constitution_of_company'), 
      description: t('constitution_of_company_description')
    },
    { 
      icon: <FaUniversity aria-label={t('bank_account_icon')} />, 
      title: t('bank_account_opening'), 
      description: t('bank_account_opening_description')
    },
    { 
      icon: <FaCalculator aria-label={t('accounting_management_icon')} />, 
      title: t('accounting_management'), 
      description: t('accounting_management_description')
    },
    { 
      icon: <FaTrademark aria-label={t('trademark_registration_icon')} />, 
      title: t('trademark_registration'), 
      description: t('trademark_registration_description')
    },
    { 
      icon: <FaBoxes aria-label={t('product_registration_icon')} />, 
      title: t('product_registration'), 
      description: t('product_registration_description')
    },
    { 
      icon: <FaPassport aria-label={t('residence_management_icon')} />, 
      title: t('residence_management'), 
      description: t('residence_management_description')
    }
  ];

  return (
    <Container>
      <div>
        <ThreeColumnContainer>
          <Heading>{t('services')}</Heading> {/* Traducción del título principal */}
          {cards.map((card, i) => (
            <Column key={i}>
              <Card 
                href={`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(defaultMessage)}`} 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label={t('learn_more_about', { service: card.title })} // Traducción accesible
              >
                <span className="imageContainer">
                  {card.icon}
                </span>
                <span className="textContainer">
                  <span className="title">{card.title}</span>
                  <p className="description">
                    {card.description}
                  </p>
                </span>
                <div className="hoverContent">{t('contact_us_for_more')}</div> {/* Traducción del hover */}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </div>
    </Container>
  );
};
