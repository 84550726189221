import { createGlobalStyle } from 'styled-components';
import { globalStyles } from 'twin.macro';
import KoHoSemiBoldItalic from 'components/misc/Fonts/KoHo-SemiBoldItalic.ttf';


const GlobalStyles = createGlobalStyle(globalStyles, `

  @font-face {
    font-family: 'KoHo';
    src: url(${KoHoSemiBoldItalic}) format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  /* Resto de tus fuentes */

  :root {
    --color1: rgb(128, 128, 229); /* Color 1 (Primario - Azul suave) */
    --color2: rgb(198, 198, 198); /* Color 2 (Gris claro) */
    --color3: rgb(126, 126, 186); /* Azul intermedio */
    --color4: rgb(80, 81, 81);   /* Gris oscuro */
    --color5: rgb(17, 17, 17);   /* Casi negro */
    --color6: rgb(255, 255, 255); /* Blanco */
  }

   /* Estilo para los headings (justificado a la izquierda) */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'KoHo', sans-serif; /* Aplica la fuente KoHo */
    font-weight: 700; /* Mantén el peso adecuado */
    text-align: left; /* Justificado por defecto en pantallas pequeñas */
    word-spacing: 0px;

    @media (min-width: 768px) {
      text-align: left; /* Justificado a la izquierda en pantallas grandes */
    }

    @media (max-width: 480px) {
      word-spacing: -1px; /* Ajuste del espaciado en pantallas pequeñas */
    }
  }


  /* Estilos para párrafos */
  p, .description {
    text-align: justify; /* Justifica los párrafos y descripciones */
    font-family: 'KoHo', sans-serif; /* Aplica la fuente KoHo */
  }

  /* Botón global */
   a.button-primary {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    border-radius: 9999px; /* Botón redondeado */
    background-color: var(--color1); /* Fondo del botón */
    color: var(--color6); /* Color del texto */
    text-decoration: none;
    font-family: 'KoHo', sans-serif; /* Fuente global */
    font-weight: 600; /* Peso adecuado para el botón */
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    max-width: 200px; /* Limita el ancho del botón */
    width: auto; /* Mantiene el tamaño natural del botón sin expandirse */
    
    &:hover {
      background-color: var(--color3); /* Cambiar color al hover */
      color: var(--color2); /* Cambiar color de texto al hacer hover */
    }

    @media (max-width: 768px) {
      font-size: 0.9rem;
      padding: 0.6rem 1.5rem;
    }
  }

  /* Media queries para ajustes de tamaño de texto en dispositivos móviles */
  @media (max-width: 1024px) {
    h1 {
      font-size: var(--font-size-3xl);
    }
    h2 {
      font-size: var(--font-size-2xl);
    }
    h3 {
      font-size: var(--font-size-xl);
    }
    h4 {
      font-size: var(--font-size-lg);
    }
    h5 {
      font-size: var(--font-size-base);
    }
    h6 {
      font-size: 0.875rem; /* 14px */
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: var(--font-size-2xl);
    }
    h2 {
      font-size: var(--font-size-xl);
    }
    h3 {
      font-size: var(--font-size-lg);
    }
    h4 {
      font-size: var(--font-size-base);
    }
    h5 {
      font-size: 0.875rem; /* 14px */
    }
    h6 {
      font-size: 0.75rem; /* 12px */
    }
  }

  @media (max-width: 480px) {
    h1 {
      font-size: var(--font-size-xl);
    }
    h2 {
      font-size: var(--font-size-lg);
    }
    h3 {
      font-size: var(--font-size-base);
    }
    h4, h5, h6 {
      font-size: 0.875rem; /* 14px */
    }
  }
  
`);

export default GlobalStyles;