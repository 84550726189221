import React, { useState, memo } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next'; // Importamos el hook de traducción

const Container = styled.div`
  ${tw`relative flex flex-col justify-center items-center`}
  background-color: var(--color1);
  padding: 3rem 2rem;
  max-width: 900px;
  min-height: 350px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
    margin-top: 6rem; /* Agrega más espacio encima del Form */
  margin-bottom: 3rem;
  
`;

const Logo = styled.img`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 25px; /* Tamaño por defecto para pantallas grandes */
  height: auto;

  @media (max-width: 1024px) { /* Tablets y pantallas más pequeñas */
    width: 20px;
    top: 0.75rem;
    left: 0.75rem;
  }

  @media (max-width: 768px) { /* Dispositivos móviles grandes */
    width: 15px;
    top: 0.5rem;
    left: 0.5rem;
  }

  @media (max-width: 480px) { /* Móviles pequeños */
    width: 15px;
    top: 0.5rem;
    left: 0.5rem;
  }
`;

const Heading = styled.h2`
  ${tw`mb-4 font-black text-3xl leading-tight`}
  font-family: 'KoHo', sans-serif;
  color: var(--color6);
  text-align: left;
`;

const Description = styled.p`
  ${tw`mb-4 text-sm md:text-base font-medium leading-relaxed`}
  font-family: 'KoHo', sans-serif;
  color: var(--color6);
  text-align: left;
`;

const Form = tw.form`text-sm flex flex-col md:flex-row md:flex-wrap gap-4 w-full`;

const Input = styled.input`
  ${tw`mb-4 p-3 w-full md:w-[48%] border-b-2 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`}
  font-family: 'KoHo', sans-serif;
  color: var(--color6);
  background-color: transparent;
  border-color: var(--color6);

  &::placeholder {
    color: var(--color6);
  }
`;

const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24 w-full`}
`;

const CustomButton = styled.button`
  ${tw`mt-4 p-3 w-full md:w-auto text-center rounded-full font-semibold tracking-wide transition duration-300`}
  background-color: var(--color6);
  color: var(--color1);

  &:hover {
    background-color: var(--color2);
    color: var(--color5);
  }
`;

const OptimizedForm = memo(({
  heading = "Solicita Presupuesto",
  description = "Se enviará un mensaje predeterminado con los datos que proporciones.",
  submitButtonText = "Enviar",
  whatsappNumber = "+5491160575569",
}) => {
  const { t } = useTranslation(); // Hook de traducción

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, subject, message } = formData;
    const whatsappMessage = `Nombre: ${firstName} ${lastName}Correo: ${email}Asunto: ${subject}Mensaje: ${message}`;
    const whatsappUrl = `https://wa.me/${whatsappNumber.replace(/\+/g, '')}?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container>
       <Logo src="/chochominimal.png" alt="Logo" /> {/* Ruta relativa desde public */}
      <Heading>{t('form.heading')}</Heading> {/* Traducción del título */}
      <Description>{t('form.description')}</Description> {/* Traducción de la descripción */}
      <Form onSubmit={handleSubmit} aria-label={t('form.aria_label')}>
        <Input
          type="text"
          name="firstName"
          placeholder={t('form.first_name')}
          value={formData.firstName}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <Input
          type="text"
          name="lastName"
          placeholder={t('form.last_name')}
          value={formData.lastName}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <Input
          type="email"
          name="email"
          placeholder={t('form.email')}
          value={formData.email}
          onChange={handleChange}
          required
          aria-required="true"
          aria-describedby="emailHelp"
        />
        <Input
          type="text"
          name="subject"
          placeholder={t('form.subject')}
          value={formData.subject}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <Textarea
          name="message"
          placeholder={t('form.message')}
          value={formData.message}
          onChange={handleChange}
          required
          aria-required="true"
        />
        <CustomButton type="submit" aria-label={t('form.submit_button')}>
          {t('form.submit_button')}
        </CustomButton>
      </Form>
    </Container>
  );
});

export default OptimizedForm;
