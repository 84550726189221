import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import myBackgroundImage from "images/ofi1.webp"; // Importa tu imagen desde src/images

const Container = styled.div`
  ${tw`relative flex flex-col justify-center items-start`}
  background-image: url(${myBackgroundImage}); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh; 
  padding-top: 2rem; /* Reducido para dispositivos móviles */
  @media (min-width: 1024px) {
    height: 90vh; 
    padding-top: 5rem; 
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 51, 0.6); /* Superposición azul oscuro */
    z-index: 1;
  }
`;

const Content = styled.div`
  ${tw`relative z-20 flex flex-col items-start max-w-screen-xl mx-auto py-8 md:py-10`}
  text-align: left;
  justify-content: center; 
  height: 100%;
`;

const LeftColumn = tw.div`relative w-full lg:w-7/12 text-left`;

const Heading = styled(motion.h1)`
  ${tw`font-black text-5xl md:text-6xl leading-tight max-w-4xl`};
  text-align: left;
  font-family: 'KoHo', sans-serif;
  font-weight: 700;
  color: var(--color6); 
  margin-top: 0; /* Elimina margen superior */
  @media (max-width: 768px) {
    margin-top: -1rem; /* Sube el texto en dispositivos móviles */
  }
`;

const SubText = styled(motion.p)`
  ${tw`mt-4 text-lg md:text-xl font-medium max-w-lg mx-auto lg:mx-0`};
  color: var(--color6);
  text-align: left;
  font-family: 'KoHo', sans-serif;
  font-weight: 300;
  margin-top: 0.5rem;
  white-space: nowrap; /* Mantiene el texto en una sola línea */
  
  @media (max-width: 768px) {
    margin-top: 0;
    white-space: normal; /* En pantallas pequeñas, permite que el texto se ajuste a varias líneas si es necesario */
    font-size: 1rem; /* Reduce el tamaño de la fuente para móviles */
  }
`;



const Actions = styled(motion.div)`
  ${tw`flex flex-col items-start sm:flex-row justify-start lg:justify-start mt-6`};
  @media (max-width: 768px) {
    margin-top: 0.5rem; /* Reduce el margen superior para móviles */
  }
`;

const Hero = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>
        <LeftColumn>
          <Heading
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }}
            aria-label={t('Chochocorp')} // Mejora la accesibilidad
          >
            Chochocorp {/* Traducción del título */}
          </Heading>
          <SubText
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.3 }}
            aria-label={t('phrase')} // Mejora la accesibilidad
          >
            {t('phrase')} {/* Traducción del subtítulo */}
          </SubText>
          <Actions
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, delay: 0.6 }}
          >
            <Link to="/servicios" className="button-primary" aria-label={t('services')}>
              {t('services')} {/* Traducción del texto del botón */}
            </Link>
          </Actions>
        </LeftColumn>
      </Content>
    </Container>
  );
};

export default Hero;
