import tw from "twin.macro";
import styled from "styled-components";

// Importa la nueva fuente
import "@fontsource/merriweather"; 

export const SectionDescription = styled.p`
  ${tw`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl`}
  font-family: 'Merriweather', serif;
`;
