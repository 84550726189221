import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter";
import Director from "components/features/TwoColWithSteps.js";

const Container = tw.div`relative`;
const TwoColumn = styled.div`
  ${tw`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`}
`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0 relative`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
  tw`w-full h-auto`,
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`
]);

const TextContent = styled.div`
  ${tw`lg:py-8`}
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.h2`
  ${tw`mt-4 font-black text-3xl sm:text-4xl lg:text-5xl leading-tight`}
  font-family: 'KoHo', sans-serif;
  font-weight: 700;
  color: var(--color5);
  text-align: justify;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const Description = styled.p`
  ${tw`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed`}
  font-family: 'KoHo', sans-serif;
  font-weight: 300;
  color: var(--color4);
  text-align: justify;

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const PrimaryButton = styled.a`
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  border-radius: 9999px; /* Botón redondeado */
  background-color: var(--color1); /* Fondo del botón */
  color: var(--color6); /* Color del texto */
  text-decoration: none;
  font-family: 'KoHo', sans-serif; /* Fuente global */
  font-weight: 600; /* Peso adecuado para el botón */
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  max-width: 200px; /* Limita el ancho del botón */
  width: auto; /* Mantiene el tamaño natural del botón sin expandirse */
  
  &:hover {
    background-color: var(--color3); /* Cambiar color al hover */
    color: var(--color2); /* Cambiar color de texto al hacer hover */
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
`;


const MainFeature1Modified = ({
  heading,
  description,
  primaryButtonText = "Ver servicios",
  primaryButtonUrl = "/servicios",
  imageSrc = "/equipo.webp",
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  textOnLeft = true
}) => {
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageRounded={imageRounded}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            alt={heading}
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <PrimaryButton href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <AnimationRevealPage>
      {/* Sección "Who We Are" */}
      <MainFeature1Modified
        heading={t('who_we_are')}
        description={t('who_we_are_description')}
        primaryButtonText={t('services')}
        primaryButtonUrl="/servicios"
        imageSrc="/equipo.webp"
        imageRounded={true}
        imageBorder={false}
        imageShadow={false}
        textOnLeft={true}
      />

      {/* Componente Director */}
      <Director />

      {/* Sección "Our Vision" */}
      <MainFeature1Modified
        heading={t('our_vision')}
        description={t('target')}  
        primaryButtonText={t('contact_us')}
        primaryButtonUrl="/form"
        imageSrc="/vision.webp"
        imageRounded={true}
        imageBorder={false}
        imageShadow={false}
        textOnLeft={false}
      />

      {/* Pie de página */}
      <Footer />
    </AnimationRevealPage>
  );
};

export default HomePage;
