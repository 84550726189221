import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // Importamos el hook de traducción

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative md:mr-8`; // Agregar margen derecho en pantallas medianas
const TextColumn = tw(Column)`md:w-6/12 mt-16 md:mt-0 relative`;

const Image = styled.img`
  ${tw`w-full h-auto`}
  border-radius: ${(props) => (props.imageRounded ? "0.375rem" : "0")};
  border: ${(props) => (props.imageBorder ? "1px solid #ccc" : "none")};
  box-shadow: ${(props) => (props.imageShadow ? "0 2px 4px rgba(0,0,0,0.1)" : "none")};
  loading: lazy; /* Carga diferida */
`;

const TextContent = tw.div`lg:py-8 flex flex-col justify-center`;

const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-justify text-gray-700`;

export default ({
  primaryButtonUrl = "https://wa.me/+5491160575569",
  imageSrc = "/quienesomos.webp",
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  textOnLeft = false
}) => {
  const { t } = useTranslation(); // Usamos el hook de traducción

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            alt={t('about_us_section.image_alt')} // Traducción del texto alternativo
            imageRounded={imageRounded}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
          />
        </ImageColumn>
        <TextColumn>
          <TextContent>
            <SectionHeading>{t('about_us_section.title')}</SectionHeading> {/* Traducción del título */}
            <Description>{t('about_us_section.description')}</Description> {/* Traducción de la descripción */}
            <Link to={primaryButtonUrl} className="button-primary">
              {t('about_us_section.primary_button_text')} {/* Traducción del texto del botón */}
            </Link>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
