import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useTranslation } from 'react-i18next'; 

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-lg mx-auto py-10 md:py-16 items-center`;
const Column = tw.div`w-full max-w-sm mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-12 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-10 md:order-first` : tw`md:ml-8 lg:ml-10 md:order-last`
]);

const Image = styled.img(props => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw`border`,
  props.imageShadow && tw`shadow`,
  tw`w-full max-w-xs md:max-w-sm`,
]);

const TextContent = tw.div`lg:py-6 text-center md:text-left`;

const Subheading = styled(SubheadingBase)`
  ${tw`text-center`}
  font-family: 'KoHo', sans-serif;
  font-size: 0.875rem;
  color: var(--color4);
  text-align: center;
`;

const Heading = styled(SectionHeading)`
  ${tw`mt-2 font-black text-center text-2xl sm:text-3xl lg:text-4xl leading-tight`}
  font-family: 'KoHo', sans-serif;
  font-size: 1.5rem;
  color: var(--color5);
  text-align: center;
`;

const List = styled.ul`
  ${tw`list-disc ml-4 mt-4`}
`;

const ListItem = styled.li`
  ${tw`mt-2 text-sm md:text-base lg:text-lg font-medium leading-relaxed`}
  font-family: 'KoHo', sans-serif;
  color: var(--color5);
  text-align: justify;
`;

export default ({
  subheading = "director",
  heading = "Sebastian Chocho",
  imageSrc = `${process.env.PUBLIC_URL}/sebifacha.webp`,
  imageAlt = "Sebastian Chocho", 
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  textOnLeft = true,
  steps = null
}) => {
  const { t } = useTranslation();

  const defaultSteps = [
    t("step_1"),
    t("step_2"),
    t("step_3"),
    t("step_4"),
    t("step_5"),
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            alt={imageAlt}
            loading="lazy" // Lazy loading para mejorar el rendimiento
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{t('director')}</Subheading>
            <Heading>{heading}</Heading>
            <List>
              {steps.map((step, index) => (
                <ListItem key={index}>
                  {step}
                </ListItem>
              ))}
            </List>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
