import React, { useEffect, useRef, useCallback } from "react";
import tw from "twin.macro"; // eslint-disable-line
import { css } from "styled-components/macro"; // eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


// Importación directa de los componentes
import Hero from "components/hero/TwoColumnWithVideo";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import Form from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter.js";
import Map from "components/map.js";

export default () => {

  // useCallback para memorizar la función del observer y evitar su recreación en cada renderizado



  return (
    <AnimationRevealPage>
      {/* Cargar todos los componentes directamente sin Suspense */}
      {/* <Hero /> */}
      <MainFeature />
      <Features />
 <Map />
      <Form />
      <Footer />
    </AnimationRevealPage>
  );
};
